<template>
    <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
        <!---弹性布局flex布局开始-->
        <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartdataStatisticsuserPortrait_flex_0">
            <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_0" isAlone="false">
                <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_0_c0" isAlone="false">
                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_0_c0_c0" isAlone="false">

                        <span class="heartdataStatisticsuserPortrait_fd0_0_c0_c0_c0">性别</span>

                    </div>

                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_0_c0_c1" isAlone="false">
                        <qiun-data-charts class=" heartdataStatisticsuserPortrait_fd0_0_c0_c1_c0" type='ring'
                            :opts='chartsOptsfd0_0_c0_c1_c0' chart-type='6' :chart-data="chartsData_549" />

                    </div>


                </div>


            </div>

            <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_1" isAlone="false">
                <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_1_c0" isAlone="false">
                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_1_c0_c0" isAlone="false">

                        <span class="heartdataStatisticsuserPortrait_fd0_1_c0_c0_c0">年龄</span>

                    </div>

                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_1_c0_c1" isAlone="false">
                        <qiun-data-charts class=" heartdataStatisticsuserPortrait_fd0_1_c0_c1_c0" type='ring'
                            :opts='chartsOptsfd0_1_c0_c1_c0' chart-type='6' :chart-data="chartsData_251" />

                    </div>


                </div>


            </div>

            <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_2" isAlone="false">
                <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_2_c0" isAlone="false">
                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_2_c0_c0" isAlone="false">

                        <span class="heartdataStatisticsuserPortrait_fd0_2_c0_c0_c0">操作系统</span>

                    </div>

                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd0_2_c0_c1" isAlone="false">
                        <qiun-data-charts class=" heartdataStatisticsuserPortrait_fd0_2_c0_c1_c0" type='ring'
                            :opts='chartsOptsfd0_2_c0_c1_c0' chart-type='6' :chart-data="chartsData_924" />

                    </div>


                </div>


            </div>

        </div>
        <!---弹性布局flex布局结束-->

        <!---栅格布局 布局开始-->
        <a-row class="benben-grid  heartdataStatisticsuserPortrait_flex_1">


            <a-col :span="24">
                <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd1_0_c0" isAlone="false">
                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd1_0_c0_c0" isAlone="false">

                        <span class="heartdataStatisticsuserPortrait_fd1_0_c0_c0_c0">注册省份</span>

                    </div>

                    <div class="flex  flex-wrap align-center heartdataStatisticsuserPortrait_fd1_0_c0_c1" isAlone="false">
                        <qiun-data-charts class=" heartdataStatisticsuserPortrait_fd1_0_c0_c1_c0" type='column'
                            :opts='chartsOptsfd1_0_c0_c1_c0' chart-type='1' :chart-data="chartsData_842" />

                    </div>


                </div>


            </a-col>



            <a-col :span="18">

            </a-col>



            <a-col :span="6">

            </a-col>


        </a-row>
        <!---栅格布局 布局结束-->



    </div>
</template>

<script>
import {
    receive,
    benbenSelectDiy,
    benbenFlexNumberBox,
    scrollList,
    treeSelect,
    simpleSelect,
    multipleSelect,
    cascaderForm,
    checkboxForm,
    switchForm,
    messageReply,
    rangePicker,
    handSignature,
    mixinAddTableItem,
    editorForm,
    qiunDataCharts,
    copyText,
    mixinTableItemChange,
    in_array,
    themeColorInit,
    getUrlKey,
    clearDataPass,
    isEmpty
} from '@/assets/diypaaspc/diy-pass-pc.js'
import {
    provinceForm
} from '@/assets/diypaaspc/province_area.js'
import request from '@/utils/request'
export default {
    name: "goods",
    components: {
        qiunDataCharts,
        benbenSelectDiy,
        //            productSelect, 
        benbenFlexNumberBox,
        scrollList,
        treeSelect,
        simpleSelect,
        messageReply,
        rangePicker,
        multipleSelect,
        provinceForm,
        editorForm,
        cascaderForm,
        switchForm,
        checkboxForm,
        //            uploadImage,
        //            uploadFile,
        //            uploadMedia,
        //            uploadSingleFile,
        handSignature
    },
    data() {
        receive(this);
        const themeColorConfig = {
            "presetApi": {
                "getClassifyOne": "",
                "getClassifyTow": "",
                "getClassifyShop": ""
            },
            "themeType": 0,
            "themeConfig": [{
                "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
            }]
        };
        return {
            themeColorConfig,
            "chartsData_842": {
                "categories": ["2016", "2017", "2018", "2019", "2020", "2021"],
                "series": [{
                    "name": "目标值",
                    "data": [35, 36, 31, 33, 13, 34]
                }, {
                    "name": "完成量",
                    "data": [18, 27, 21, 24, 6, 28]
                }]
            },
            "chartsOptsfd1_0_c0_c1_c0": {
                "enableScroll": false,
                "legend": [],
                "yAxis": {
                    "data": [{
                        "min": 0
                    }]
                },
                "xAxis": {
                    "disableGrid": true
                },
                "padding": [15, 15, 0, 5],
                "extra": {
                    "column": {
                        "type": "group",
                        "width": 30,
                        "activeBgColor": "#000000",
                        "activeBgOpacity": 0.08
                    }
                }
            },
            "chartsData_924": {
                "series": [{
                    "name": "一班",
                    "data": 50
                }, {
                    "name": "二班",
                    "data": 30
                }, {
                    "name": "三班",
                    "data": 20
                }, {
                    "name": "四班",
                    "data": 18
                }, {
                    "name": "五班",
                    "data": 8
                }]
            },
            "chartsData_251": {
                "series": [{
                    "name": "一班",
                    "data": 50
                }, {
                    "name": "二班",
                    "data": 30
                }, {
                    "name": "三班",
                    "data": 20
                }, {
                    "name": "四班",
                    "data": 18
                }, {
                    "name": "五班",
                    "data": 8
                }]
            },
            "chartsData_549": {
                "series": [{
                    "name": "一班",
                    "data": 50
                }, {
                    "name": "二班",
                    "data": 30
                }, {
                    "name": "三班",
                    "data": 20
                }, {
                    "name": "四班",
                    "data": 18
                }, {
                    "name": "五班",
                    "data": 8
                }]
            },
            "chartsOptsfd0_0_c0_c1_c0": {
                "padding": [5, 5, 5, 5],
                "dataLabel": true,
                "enableScroll": false,
                "legend": {
                    "show": true,
                    "position": "bottom"
                },
                "title": {
                    "name": "\\u6536\\u76ca\\u7387",
                    "fontSize": 15,
                    "color": "#666666"
                },
                "subtitle": {
                    "name": "70%",
                    "fontSize": 25,
                    "color": "#7cb5ec"
                },
                "extra": {
                    "ring": {
                        "ringWidth": 60,
                        "activeOpacity": 0.5,
                        "activeRadius": 10,
                        "offsetAngle": 0,
                        "labelWidth": 15,
                        "border": false,
                        "borderWidth": 3,
                        "borderColor": "#FFFFFF"
                    }
                }
            },
            "chartsOptsfd0_1_c0_c1_c0": {
                "padding": [5, 5, 5, 5],
                "dataLabel": true,
                "enableScroll": false,
                "legend": {
                    "show": true,
                    "position": "bottom"
                },
                "title": {
                    "name": "\\u6536\\u76ca\\u7387",
                    "fontSize": 15,
                    "color": "#666666"
                },
                "subtitle": {
                    "name": "70%",
                    "fontSize": 25,
                    "color": "#7cb5ec"
                },
                "extra": {
                    "ring": {
                        "ringWidth": 60,
                        "activeOpacity": 0.5,
                        "activeRadius": 10,
                        "offsetAngle": 0,
                        "labelWidth": 15,
                        "border": false,
                        "borderWidth": 3,
                        "borderColor": "#FFFFFF"
                    }
                }
            },
            "chartsOptsfd0_2_c0_c1_c0": {
                "padding": [5, 5, 5, 5],
                "dataLabel": true,
                "enableScroll": false,
                "legend": {
                    "show": true,
                    "position": "bottom"
                },
                "title": {
                    "name": "\\u6536\\u76ca\\u7387",
                    "fontSize": 15,
                    "color": "#666666"
                },
                "subtitle": {
                    "name": "70%",
                    "fontSize": 25,
                    "color": "#7cb5ec"
                },
                "extra": {
                    "ring": {
                        "ringWidth": 60,
                        "activeOpacity": 0.5,
                        "activeRadius": 10,
                        "offsetAngle": 0,
                        "labelWidth": 15,
                        "border": false,
                        "borderWidth": 3,
                        "borderColor": "#FFFFFF"
                    }
                }
            }
        };

    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    },
    watch: {},
    methods: {
        copyText,
        mixinTableItemChange,
        in_array,
        themeColorInit,
        isEmpty,

    }
}
</script>

<style lang="less" scoped>
@import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

.antdVue {
    width: 100%;
    background: #F8F8F8;
    background-size: 100% auto !important;
}

#antdVue .heartdataStatisticsuserPortrait_flex_0 {
    background: #fff;
    width: 100%;
    min-height: 100px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2_c0_c1_c0 {
    width: 100%;
    height: 400px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2_c0_c1 {
    width: 100%;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2_c0_c0_c0 {
    font-size: 16px;
    font-style: normal;
    color: rgba(51, 51, 51, 1);
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2_c0_c0 {
    width: 100%;
    padding: 0px 0px 0px 15px;
    margin: 0px 0px 19px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2_c0 {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    background-size: 100% auto !important;
    padding: 15px 0px 22px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_2 {
    width: 33%;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1_c0_c1_c0 {
    width: 100%;
    height: 400px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1_c0_c1 {
    width: 100%;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1_c0_c0_c0 {
    font-size: 16px;
    font-style: normal;
    color: rgba(51, 51, 51, 1);
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1_c0_c0 {
    width: 100%;
    padding: 0px 0px 0px 15px;
    margin: 0px 0px 19px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1_c0 {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    background-size: 100% auto !important;
    padding: 15px 0px 22px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_1 {
    width: 33%;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0_c0_c1_c0 {
    width: 100%;
    height: 400px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0_c0_c1 {
    width: 100%;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0_c0_c0_c0 {
    font-size: 16px;
    font-style: normal;
    color: rgba(51, 51, 51, 1);
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0_c0_c0 {
    width: 50%;
    padding: 0px 0px 0px 15px;
    margin: 0px 0px 19px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0_c0 {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    background-size: 100% auto !important;
    padding: 15px 0px 22px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd0_0 {
    width: 33%;
}

#antdVue .heartdataStatisticsuserPortrait_flex_1 {
    background: #fff;
    width: 100%;
}

#antdVue .heartdataStatisticsuserPortrait_fd1_0_c0_c1_c0 {
    width: 100%;
    height: 230px;
}

#antdVue .heartdataStatisticsuserPortrait_fd1_0_c0_c1 {
    width: 100%;
    padding: 0px 25px 0px 15px;
}

#antdVue .heartdataStatisticsuserPortrait_fd1_0_c0_c0_c0 {
    font-size: 16px;
    font-style: normal;
    color: rgba(51, 51, 51, 1);
}

#antdVue .heartdataStatisticsuserPortrait_fd1_0_c0_c0 {
    width: 100%;
    padding: 0px 0px 0px 15px;
    margin: 0px 0px 19px 0px;
}

#antdVue .heartdataStatisticsuserPortrait_fd1_0_c0 {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    background-size: 100% auto !important;
    padding: 15px 0px 22px 0px;
}</style>